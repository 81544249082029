import React from "react";
import { graphql } from "gatsby";
import Layout from "@/components/layout"
import Sections from "@/components/sections"
import SEO from "@/components/seo"
import Footer from "@/components/elements/footer"
// import CustomCursor from "../components/elements/customCursor"
// import MouseContextProvider from "../context/mouseContext";
// import { GatsbyImage } from "gatsby-plugin-image";
// import Moment from "react-moment";
// import Layout from "../../components/layout";
// import Markdown from "react-markdown";


const Article = ({ data, pageContext }) => {
  // console.log(data);
  const article = data.strapiArticle;
  const { contentSections, metadata, localizations } = data.strapiArticle;
  const global = data.strapiGlobal;
  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.image,
    article: true,
  };

  return (
    <>
      <SEO seo={seo} global={global} />


        <Layout global={global} pageContext={{ ...pageContext, localizations }}>
          <Sections sections={contentSections} pageGlobal={data}/>
          <Footer footer={global.footer} />
        </Layout>
    </>
  );
};

export default Article;


export const query = graphql`
  fragment GlobalData on StrapiGlobal {
    favicon {
      localFile {
        publicURL
      }
    }
    footer {
      id
      columns {
        id
        links {
          id
          newTab
          text
          url
        }
        title
      }
      id
      logo {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      smallText
    }
    id
    metaTitleSuffix
    metadata {
      metaDescription
      metaTitle
      shareImage {
        localFile {
          publicURL
        }
      }
    }
    navbar {
      button {
        id
        newTab
        text
        type
        url
      }
      id
      links {
        url
        text
        newTab
        id
        transitionWord
        transitionWordFunny
      }
      logo {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    notificationBanner {
      id
      text
      type
    }
  }

  query ArticleQuery($slug: String!, $locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiArticle(slug: { eq: $slug }) {
      slug
      strapiId
      title
      order
      year
      client
      website_link{
        url
        text
        newTab
      }
      categories {
        name
        slug
      }
      description
      content
      published_at
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
      localizations {
        id
        locale
      }
      contentSections
    }
  }
`