import React from "react"
import Image from "../image"
import PropTypes from "prop-types"
import { linkPropTypes, mediaPropTypes } from "@/utils/types"
import CustomLink from "./custom-link"

const Footer = ({ footer }) => {
  return (
    <footer className="px-5 bg-black xl:px-20" style={{zIndex:"29"}}>
      <div className="flex flex-col min-h-screen pt-20 pb-5 mt-20 md:pb-10 md:justify-between">
        <div className="flex flex-col-reverse items-center text-white md:flex-row md:justify-between justify-self-stretch" style={{flex:"1 1 0"}}>
          <div className="self-start pt-10 md:self-end md:pt-0" style={{height:"40%", fontSize:"16px", fontStyle:"normal", flex:"1 1 0"}}>
            <address style={{fontStyle:"normal"}}><p className="pb-5">Largo do Bairro Social, N° 52</p><p className="pb-5">4740-596 Palmeira de Faro</p></address>
            <CustomLink link={{url:"mailto:geral@antecamarastudio.com", newTab: true}}><a className="mb-5 text-white hover:text-white" style={{display:"block", fontWeight:"400"}} href="mailto:geral@antecamarastudio.com">geral@antecamarastudio.com</a></CustomLink>
            <CustomLink link={{url:"tel:+351939464349", newTab: true}}><a className="mb-5 text-white hover:text-white" style={{display:"block", fontWeight:"400"}} href="tel:+351939464349">+351 939 464 349<sup>*</sup></a></CustomLink>
            <CustomLink link={{url:"tel:+351939464349", newTab: true}}><a className="mb-5 text-white hover:text-white" style={{display:"block", fontWeight:"400"}} href="tel:+351915639427">+351 915 639 427<sup>*</sup></a></CustomLink>
            <p style={{fontSize:"12px"}}><sup>*</sup>Calls to portuguese mobile line</p>
            <div className="flex mt-20 md:hidden">
              <CustomLink link={{url:"https://www.instagram.com/antecamarastudio/", newTab: true}} ><h1 className="text-white h1" style={{ fontSize: "23px", fontWeight:"300" }}>IG</h1></CustomLink>
              <CustomLink link={{url:"https://www.behance.net/antecamara", newTab: true}} ><h1 className="text-white h1" style={{ fontSize: "23px", fontWeight:"300", marginLeft:"2ch" }}>BE</h1></CustomLink>
              <CustomLink link={{url:"https://www.facebook.com/antecamarastudio", newTab: true}} ><h1 className="text-white h1" style={{ fontSize: "23px", fontWeight:"300", marginLeft:"2ch"  }}>FB</h1></CustomLink>
            </div>
          </div>
          <div className="flex items-end self-start flex-column md:gap-20 md:items-end " style={{height:"60%", flex:"1 1 0"}}>
            <div>
              <p className="py-5" style={{paddingLeft:"0.5vw"}}>GOT A PROJECT IN MIND?</p>
              <CustomLink link={{url:"mailto:geral@antecamarastudio.com", newTab: true}}><h1 style={{fontSize:"8vmax", fontWeight:"400", color:"#FBF3ED"}}>Let's talk</h1></CustomLink>
            </div>
          </div>
        </div>
        <div className="md:flex gap-5 items-end pt-10 text-sm text-white bg-black md:align-self-end" style={{justifySelf:"flex-end"}}>
          <div className="" style={{ flex: "1 1 0" }}>
            <CustomLink link={{url:"https://antecamarastudio.com/ficha-de-projeto.pdf", newTab: true}}>
              <div className="bg-white max-w-full inline-block max-h-20">
                <img src="/feder.png" alt="Projeto 2020 logos" className="max-h-20 h-full w-full"/>
              </div>
            </CustomLink>
            {/* {footer.smallText} */}
          </div>

          <div className="md:flex justify-between hidden" style={{ flex: "1 1 0" }}>
            <div className="hidden md:flex">
              <CustomLink link={{url:"https://www.instagram.com/antecamarastudio/", newTab: true}}><h1 className="text-white h1" style={{ fontSize: "23px", fontWeight:"300" }}>IG</h1></CustomLink>
              <CustomLink link={{url:"https://www.behance.net/antecamara", newTab: true}} ><h1 className="text-white h1" style={{ fontSize: "23px", fontWeight:"300", marginLeft:"4ch" }}>BE</h1></CustomLink>
              <CustomLink link={{url:"https://www.facebook.com/antecamarastudio", newTab: true}} ><h1 className="text-white h1" style={{ fontSize: "23px", fontWeight:"300", marginLeft:"4ch"  }}>FB</h1></CustomLink>
            </div>
            <div className="ml-auto"><h1 style={{ fontSize: "30px", fontWeight:"500" }}>Studio</h1></div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  footer: PropTypes.shape({
    logo: mediaPropTypes,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        title: PropTypes.string.isRequired,
        links: PropTypes.arrayOf(linkPropTypes),
      })
    ),
    smallText: PropTypes.string.isRequired,
  }),
}

export default Footer
